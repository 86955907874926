<template>
	<form>
		<b-card title="Edit Question">
			<b-row>
				<b-col cols="12" md="6">
					<div class="form-group">
						<label>Text*</label>
						<textarea 
							type="text" 
							class="form-control" 
							v-model="formCopywriting.text"
						>
						</textarea>
					</div>
					<!-- <div class="form-group">
						<label>Bisa Skip?</label>
						<div class="demo-inline-spacing">
							<div class="custom-control custom-radio mt-0">
								<input 
									name="inlineRadioOptions1" 
									class="custom-control-input" 
									type="radio" 
									id="radio1" 
									v-bind:value="1"
									:checked="formCopywriting.can_skip == 1"
								>
								<label 
									class="custom-control-label" 
									for="radio1"
								>
									Iya
								</label>
							</div>
							<div class="custom-control custom-radio mt-0">
								<input 
									name="inlineRadioOptions1" 
									class="custom-control-input" 
									type="radio" 
									id="radio2"
									v-bind:value="0"
									:checked="formCopywriting.can_skip == 0"
								>
								<label 
									class="custom-control-label" 
									for="radio2"
								>
									Tidak
								</label>
							</div>
						</div>
					</div> -->
				</b-col>
				<b-col cols="12" md="6">
					<div class="form-group">
						<label>Hint*</label>
						<textarea 
							type="text" 
							class="form-control" 
							v-model="formCopywriting.hint"
						>
						</textarea>
					</div>
				</b-col>
			</b-row>
		</b-card>
		<b-card 
			title="Edit Editional Text"
			v-if="formCopywriting.comments != ''"
		>
			<b-col 
				cols="12"
				class="p-0"
				v-for="comment in formCopywriting.comments"
				:key="comment.uuid"
			>
				<div class="form-group">
					<label>Comment</label>
					<textarea
						class="form-control"
						v-model="comment.text"
					>
					</textarea>
				</div>
			</b-col>
		</b-card>
		<b-row>
			<b-col
				cols="12"
				md="6"
				v-for="(optionsList, index) in formCopywriting.options"
				:key="optionsList.uuid"
			>
				<b-card>
					<h3>Option {{ index + 1 }}</h3>
					<div class="form-group">
						<label>Option Label</label>
						<input 
							type="text"
							class="form-control"
							v-model="optionsList.text"
						>
					</div>
					<!-- <div class="form-row">
						<div class="form-group col-md-6">
							<label>Validation Min</label>
							<input
								type="number"
								class="form-control"
								v-model="optionsList.validation_min"
							>
						</div>
						<div class="form-group col-md-6">
							<label>Validation Max</label>
							<input 
								type="number"
								class="form-control"
								v-model="optionsList.validation_max"
							>
						</div>
					</div> -->
					<div class="form-group">
						<label>Hint</label>
						<textarea 
							class="form-control"
							v-model="optionsList.hint"
						></textarea>
					</div>
				</b-card>
			</b-col>
		</b-row>
		<b-col cols="12" class="p-0 mt-2">
			<button
				variant="primary"
				class="btn waves-effect waves-float waves-light btn-primary"
				@click.prevent="saveComplitingprofile"
			>
				Save
			</button>
			&nbsp;
			&nbsp;
			<a
				@click="handleBackButton()"
				variant="secondary"
				class="btn waves-effect waves-float waves-light btn-secondary"
			>
				Cancel
			</a>
		</b-col>
	</form> 
</template>

<script>
import {
	BCard, 
	BFormGroup, 
	BFormRadio, 
	BRow, 
	BCol,
	BFormCheckbox
} from 'bootstrap-vue'
import { successNotification, errorNotification } from '@/auth/utils'
export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormGroup,
		BFormRadio,
		BFormCheckbox
	},

	data() {
		return {
			formCopywriting:{
				text: '',
				hint: '',
				// can_skip: '',
				comments: [
					{
						text: '',
					}
				],
				options: [
					{
						text: '',
						// validation_min: '',
						// validation_max: '',
						hint: ''
					}
				],
			},
		}
	},

	mounted() {
		this.loadCopywriting()
	},


	methods: {
		handleBackButton() {
			history.back()
		},
		async loadCopywriting() {
			const params = this.$route.params.uuid
			await this.$http.get('admin/chatbot/compliting-profile/' + params)
				.then(response => {
					this.formCopywriting = response.data.data
				})
		},
		saveComplitingprofile() {
			const params = this.$route.params.uuid
			this.$http.post('admin/chatbot/compliting-profile/' + params, this.formCopywriting)
			.then(response => {
				this.loadCopywriting()
				successNotification(this, 'Success', 'Compiliting Profile sukses diedit!')
				this.$router.push({ name: 'compliting-profile' })
				
			}).catch(error => {
				if (error.response.data.meta.validations) {
					errorNotification(this, 'Oops!', 'Ada Kesalahan Teknis!')
				}
			})
		}
	},

	created() {
		
	}

}
</script>

<style scoped>
	
</style>